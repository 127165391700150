<template>
  <v-dialog v-model="whotherDialog" scrollable max-width="1000" persistent>
    <v-card>
      <v-card-title>WHO ICD Diagnosis Clasification Search</v-card-title>

      <v-toolbar color="#f1f6ff" flat style="padding: 0">
        <v-text-field
          :loading="icd.loading"
          dense
          label="Type Search name here"
          hide-details
          v-model="icd.searching"
          outlined
          clearable
          @click.clear="clear"
        ></v-text-field>
        <v-btn :loading="icd.loading" text icon @click="searchItem">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="unit_select">
        <v-list dense rounded style="padding: 1px 0 !important">
          <v-list-item-group v-model="selectedItem" color="primary">
            <v-list-item
              class="icd"
              v-for="item in icd.resultList"
              :key="item.id"
              @click="mySelection(item.id, item.theCode, item.title)"
            >
              <v-list-item-content>
                <v-list-item-title v-html="item.title"> </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                {{ item.theCode }}
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <!-- <ul>
          <li class="icd" v-for="item in icd.resultList" :key="item.id">
            <div class="leftie" v-html="item.title"></div>
            <div class="rightie">
              <v-btn
                text
                small
                color="success"
                @click.stop="mySelection(item.id, item.theCode, item.title)"
              >
                {{ item.theCode }}
              </v-btn>
            </div>
            <div class="clearAll"></div>
          </li>
        </ul> -->
        <!-- <div v-if="icd.resultList.length > 0">
          <ul v-for="u in icd.resultList" :key="u.id">
            <li>
              <v-checkbox
                dense
                hide-details
                :id="parseInt(u.id)"
                :value="u"
                v-model="create.selectedDetail"
                :label="`${item.theCode}, ${item.title}`"
              ></v-checkbox>
            </li>
          </ul>
        </div> -->
      </v-card-text>
      <v-card-actions>
        <div
          style="font-weight: 400; color: #1c1c73"
          class="icd"
          v-html="icd.selected"
        ></div>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="blue" small text @click="submit" v-on="on">
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
export default {
  props: {
    whotherDialog: Boolean,
  },
  data() {
    return {
      loading: false,
      selectedItem: null,
      icd: {
        loading: false,
        searching: null,
        resultList: [],
        selected: null,
        submission: null,
        entity: {},
      },
    };
  },
  watch: {
    "icd.searching": function (a) {
      if (a === null) {
        this.icd.resultList = [];
      } else {
        this.diagnosiSearch(a);
      }
    },
  },
  created() {},
  methods: {
    searchItem() {
      let searching = this.icd.searching;
      console.log("searching: ", searching);
      this.diagnosiSearch(searching);
    },
    clearable(a) {
      console.log("a", a);
      this.icd.resultList = [];
    },
    diagnosiSearch(text) {
      this.icd.loading = true;
      let searching = text;
      let self = this;
      let data = { searching };
      Restful.medics.treatment.diagnosis
        .linearSearch(data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.icd.resultList = response.data.destinationEntities;
          }
          this.icd.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.icd.loading = false;
        });
    },
    mySelection(a, b, c) {
      let id = a.replaceAll(
        "http://id.who.int/icd/release/11/2023-01/mms/",
        ""
      );
      let theCode = b;
      let title = c;
      let data = { id, theCode, title };
      console.log("data: ", data);
      this.icd.submission = data;
      this.icd.selected = theCode + ", " + title;
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeWhose", payload);
    },
    submit() {
      let payload = { state: false, data: this.icd.submission };
      this.$emit("submitWhose", payload);
    },
  },
};
</script>
