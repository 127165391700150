<template>
  <div style="padding: 32px 0px 16px" v-resize="onResize">
    <v-card-text class="view_data">
      <v-overlay absolute dark :value="overlay"> </v-overlay>

      <v-tabs v-model="myLabs">
        <v-tab key="general">laboratory request</v-tab>
        <v-tab key="imaging">imaging request</v-tab>
        <v-tab key="other">other request</v-tab>
      </v-tabs>
      <v-tabs-items v-model="myLabs">
        <v-tab-item key="general" style="padding-top: 20px">
          <div class="scrollable" :style="{ height: panheight }">
            <v-card flat>
              <v-card-text style="padding: 0 6px">
                <v-card outlined style="margin-bottom: 20px">
                  <v-card-text style="padding: 16px !important">
                    <v-card flat>
                      <v-card-text style="padding: 0 6px">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-row>
                              <v-col cols="12" sm="6">
                                <div class="data_text">
                                  <ul>
                                    <li>
                                      <div class="my_title">drug therapy</div>
                                      <div class="my_text">
                                        {{ view.drug_therapy }}
                                      </div>
                                    </li>
                                    <li>
                                      <div class="my_title">fasting</div>
                                      <div
                                        class="my_text"
                                        :style="{
                                          color: view.fasting ? 'blue' : 'red',
                                        }"
                                      >
                                        {{ view.fasting ? "Yes" : "No" }}
                                      </div>
                                    </li>
                                    <li>
                                      <div class="my_title">urgency</div>
                                      <div
                                        class="my_text"
                                        :style="{
                                          color: view.urgency ? 'blue' : 'red',
                                        }"
                                      >
                                        {{ view.urgency ? "Yes" : "No" }}
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </v-col>
                              <v-col cols="12" sm="6">
                                <div class="data_text">
                                  <ul>
                                    <li>
                                      <div class="my_title">last taken</div>
                                      <div class="my_text">
                                        {{ view.last_dose_date_time | myDate }}
                                      </div>
                                    </li>
                                    <li>
                                      <div class="my_title">
                                        clinical information
                                      </div>
                                      <div class="my_text">
                                        {{ view.clinical_info }}
                                      </div>
                                    </li>
                                    <li>
                                      <div class="my_title">accepted</div>
                                      <div
                                        class="my_text"
                                        :style="{
                                          color: view.accepted ? 'blue' : 'red',
                                        }"
                                      >
                                        {{ view.accepted ? "Yes" : "No" }}
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-card outlined>
                              <v-card-text>
                                <div class="data_text">
                                  <ul>
                                    <li>
                                      <div
                                        class="my_title"
                                        style="font-size: 0.8em"
                                      >
                                        other instructions
                                      </div>
                                      <div class="my_text">
                                        {{ view.profile_test }}
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>

                <v-card
                  class="pa-2 dyna_form_data"
                  outlined
                  style="margin-bottom: 20px"
                  v-for="item in view.specimens"
                  :key="item.id"
                >
                  <v-toolbar dense flat color="rgb(245, 245, 245)">
                    <v-toolbar-title>
                      {{ item.name }}
                    </v-toolbar-title>
                  </v-toolbar>
                  <v-card-text style="padding: 20px 0 10px">
                    <v-row>
                      <v-col
                        v-for="d in item.detail"
                        :key="d.id"
                        outlined
                        class="pa-2"
                        tile
                      >
                        <v-card
                          outlined
                          style="
                            min-width: 180px;
                            padding-bottom: 10px;
                            margin: 16px 0;
                          "
                        >
                          <v-card-title>{{ d.name }}</v-card-title>
                          <v-card-text>
                            <ul v-for="u in d.units" :key="u.id">
                              <li>
                                <v-icon :color="u.active ? 'blue' : 'red'">
                                  {{ u.active ? "mdi-check" : "mdi-home" }}
                                </v-icon>
                                {{ u.name }}
                              </li>
                            </ul>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-card-text>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item key="imaging" style="padding-top: 20px">
          <div class="scrollable" :style="{ height: panheight }">
            <div class="listos" v-html="view.imaging"></div>
          </div>
        </v-tab-item>
        <v-tab-item key="other" style="padding-top: 20px">
          <div class="scrollable" :style="{ height: panheight }">
            <div class="listos" v-html="view.other_specimens"></div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon color="info" v-on="on" @click="refresh">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Refresh data</span>
      </v-tooltip>
    </v-card-actions>
  </div>
</template>
<script>
import Restful from "@/services/RestFul";
import Util from "@/mixins/utils";
import { VCard } from "vuetify/lib";
export default {
  props: { id: { type: String } },
  mixins: [Util],
  data: () => ({
    overlay: true,
    myLabs: null,
    view: {},
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 450 + "px";
      return h;
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.overlay = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.labs
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.view = response.data;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    refresh() {
      this.find();
    },
  },
  components: { VCard },
};
</script>
