<template>
  <div v-resize="onResize">
    <v-card flat>
      <v-card-text class="view_data">
        <v-overlay absolute dark :value="overlay"> </v-overlay>

        <v-tabs v-model="myLabs">
          <v-tab key="exam">examination findings</v-tab>
          <v-tab key="summary">summary of exam findings</v-tab>
        </v-tabs>
        <v-tabs-items v-model="myLabs">
          <v-tab-item key="exam" style="padding-top: 20px">
            <div class="scrollable" :style="{ height: panheight }">
              <div class="listos" v-html="view.exam_findings"></div>
            </div>
          </v-tab-item>
          <v-tab-item key="summary" style="padding-top: 20px">
            <div class="scrollable" :style="{ height: panheight }">
              <div class="listos" v-html="view.summary"></div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon color="info" v-on="on" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh data</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import Restful from "@/services/RestFul";
import Util from "@/mixins/utils";
export default {
  props: { id: { type: String } },
  mixins: [Util],
  data: () => ({
    overlay: true,
    myLabs: null,
    view: {},
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 450 + "px";
      return h;
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.overlay = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.examfind
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.view = response.data;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    refresh() {
      this.find();
    },
  },
};
</script>
