<template>
  <div v-resize="onResize">
    <v-toolbar flat dense style="margin-bottom: 4px">
      <v-toolbar-title> {{ name | capitalize }} </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text x-small color="primary" v-show="isNew">new</v-btn>
      <v-btn text x-small color="primary" @click="view" v-show="!isNew">
        view
      </v-btn>
      <v-btn text x-small color="success" @click="edit" v-show="isEdit">
        edit
      </v-btn>
      <!--  -->
    </v-toolbar>
    <v-card
      :loading="loading"
      loader-height="1"
      flat
      :style="{ height: panheight }"
    >
      <div v-if="isNew === true">
        <NewOne :id="id" @submitNew="submitNew" />
      </div>
      <div v-else>
        <div v-if="editNow === true">
          <EditData :id="id" @submitEdit="submitEdit" />
        </div>
        <div v-else-if="editNow === false">
          <ViewData :id="id" />
        </div>
      </div>
    </v-card>
    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../../../mixins/utils";
import NewOne from "../prescription/newone.vue";
import ViewData from "../prescription/view.vue";
import EditData from "../prescription/edit.vue";
import { mapGetters } from "vuex";
import { mapActions } from "vuex";
export default {
  props: {
    panh: { type: Number },
    id: { type: String },
    name: { type: String },
    patid: { type: String },
  },
  components: { NewOne, ViewData, EditData },
  mixins: [Util],
  data: () => ({
    myId: 0,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    windowSize: {},
    overlay: true,
    loading: false,
    isPresent: false,
    isNew: true,
    isEdit: false,
    isDisabled: true,
    editNow: false,
    viewNow: false,
  }),
  computed: {
    ...mapGetters(["getPresc"]),
    ...mapActions(["removeAll"]),
    panheight: function () {
      let h = this.windowSize.y - 280 + "px";
      return h;
    },
  },
  created() {
    this.prescribe();
  },
  methods: {
    prescribe() {
      Restful.medics.treatment.prescription
        .finished(this._decode(this.id))
        .then((response) => {
          console.log(response);
          if (response.data === true) {
            this.isNew = false;
            this.viewNow = true;
            this.isEdit = true;
          } else if (response.data === true) {
            this.isNew = true;
            this.viewNow = false;
            this.isEdit = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    refresh() {},
    view() {
      this.editNow = false;
    },
    edit() {
      this.editNow = true;
    },
    onResize() {
      // console.log(a);
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    submitNew(p) {
      this.snack.text = "Processing ...";
      this.snack.color = "primary";
      this.snack.bar = true;
      console.log("in", p);
      console.log("in", JSON.stringify(p));
      let self = this;
      Restful.medics.treatment.prescription
        .upload(p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.prescribe();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log("in", p);
      let self = this;
      Restful.medics.treatment.prescription
        .update(p.id, p.data)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.editNow = false;
          this.prescribe();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      console.log("p", p);
    },
    closeRemove(p) {
      this.removeDialog = p.state;
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log("in", p);
      let self = this;
      Restful.medics.treatment.history
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.list();
          this.isNew = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.removeDialog = p.state;
    },
    submission() {
      let jsonData = this.getPresc;
      let data = { jsonData };

      let self = this;
      Restful.medics.treatment.prescription
        .upload(data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.list();
          this.removeAll;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });

      console.log(JSON.stringify(data));
    },
  },
};
</script>
