<template>
  <v-dialog
    v-model="addDialog"
    class="prescribe"
    scrollable
    persistent
    max-width="1000"
  >
    <v-card>
      <v-card-title>new prescription</v-card-title>
      <v-card-text>
        <v-card outlined style="margin-bottom: 20px">
          <v-text-field
            solo
            :loading="loading"
            loader-height="2"
            placeholder="Search for Medicine"
            hide-details
            v-model="searchMedicine"
            label="Search for Medicine"
            clearable
          ></v-text-field>
          <v-card-text>
            <div v-if="medicinesFilter.length > 0">
              <v-data-table
                dense
                calculate-widths
                :headers="headers"
                :items="medicinesFilter"
                :items-per-page="5"
                :sort-by="['id']"
                :sort-desc="[true]"
                single-select
                item-key="id"
                v-model="prescribe.selected"
                show-select
                @click:clear="closeSearch"
              >
              </v-data-table>

              <div class="mySelect" v-if="prescribe.selected.length > 0">
                <span>Selected item</span>: {{ prescribe.selected[0].name }}
              </div>
            </div>
          </v-card-text>
        </v-card>

        <v-text-field
          outlined
          style="font-size: 0.8em"
          label="specify brand"
          dense
          v-model="prescribe.medi_specify"
          hint="Like prefered brand"
          persistent-hint
        ></v-text-field>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-select
              label="Route"
              :items="routeList"
              item-value="id"
              item-text="name"
              style="font-size: 0.8em"
              v-model="prescribe.route_name"
              return-object
              outlined
              dense
              hint="How it will be administered"
              persistent-hint
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              label="frequency"
              hint="Frequency of usage"
              style="font-size: 0.8em"
              :items="freqList"
              v-model="prescribe.frequency_name"
              return-object
              item-value="id"
              item-text="name"
              persistent-hint
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              style="font-size: 0.8em"
              label="strength"
              v-model="prescribe.strength"
              hint="e.g. 1mg"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              style="font-size: 0.8em"
              type="number"
              label="duration"
              v-model="prescribe.duration"
              hint="in days only"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              style="font-size: 0.8em"
              label="directions"
              hint="e.g. Before meals"
              v-model="prescribe.directions"
              persistent-hint
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              dense
              outlined
              style="font-size: 0.8em"
              label="quantity"
              hint="e.g. 10 tablets"
              v-model="prescribe.quantity"
              persistent-hint
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              small
              text
              color="blue"
              v-on="on"
              @click="submit"
              :disabled="$v.prescribe.$invalid"
            >
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

//   import moment from "moment";
export default {
  props: {
    addDialog: Boolean,
    myId: { type: String },
  },
  mixins: [Utils, validationMixin],
  data() {
    return {
      routeList: [],
      freqList: [],
      loading: false,
      searchMedicine: null,
      medicinesFilter: [],
      mediFil: false,
      selectedItem: false,
      headers: [
        { text: "name", value: "name" },
        { text: "eml", value: "eml" },
        { text: "formulations", value: "formulations" },
      ],
      prescribe: {
        selected: [],
        medi_category: null,
        pharma_categoryid: null,
        medi_specify: null,
        route_name: null,
        frequency_name: null,
        strength: null,
        duration: null,
        directions: null,
        quantity: null,
      },
      // mandatory validation: route, freq, strength, duration
    };
  },
  validations: {
    prescribe: {
      // selected: {
      //   required,
      // },
      route_name: {
        required,
      },
      frequency_name: {
        required,
      },
      strength: {
        required,
      },
      duration: {
        required,
      },
    },
  },
  watch: {
    searchMedicine: function (a) {
      if (a === null) {
        console.log("a", a);
        this.medicinesFilter = [];
      } else {
        console.log("a", a);
        this.searchText(a);
      }
    },
  },
  created() {
    this.route();
    this.freq();
  },
  methods: {
    searchText(txt) {
      this.loading = true;
      let text = txt;
      let data = { text };
      let self = this;
      Restful.general.medicines
        .search(data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.medicinesFilter = response.data;
          } else {
            this.medicinesFilter = [];
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeSearch(e) {
      console.log("close", e);
      this.medicinesFilter = [];
    },
    // medicat() {
    //   Restful.general.medicat
    //     .list()
    //     .then((response) => {
    //       console.log(response.data);
    //       if (response.status === 200) {
    //         this.medicatList = response.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // },
    // pharmacat() {
    //   Restful.general.pharmacat
    //     .shortlist()
    //     .then((response) => {
    //       console.log(response.data);
    //       if (response.status === 200) {
    //         this.pharmacatList = response.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response);
    //     });
    // },
    route() {
      Restful.general.medicroute
        .list()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.routeList = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    freq() {
      Restful.general.medicfreqency
        .list()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.freqList = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    closeDialog() {
      let payload = { id: this.myId, state: false };
      this.$emit("closeAdd", payload);
    },
    submit() {
      let medicine_id = this.prescribe.selected[0].id;
      let medicine_name = this.prescribe.selected[0].name;

      let medi_specify = this.prescribe.medi_specify;

      let route_name_id = this.prescribe.route_name.id;
      let route_name = this.prescribe.route_name.name;

      let frequency_name_id = this.prescribe.frequency_name.id;
      let frequency_name_name = this.prescribe.frequency_name.name;

      let strength = this.prescribe.strength;
      let duration = this.prescribe.duration;
      let directions = this.prescribe.directions;
      let quantity = this.prescribe.quantity;

      let data = {
        medicine_id,
        medicine_name,
        medi_specify,
        route_name_id,
        route_name,
        frequency_name_id,
        frequency_name_name,
        strength,
        duration,
        directions,
        quantity,
      };
      let payload = { data: data, state: false };
      console.log("payload", payload);
      this.$emit("submitAdd", payload);
      this.clearAll();
    },
    clearAll() {
      this.searchMedicine = null;
      this.prescribe.route_name = null;
      this.prescribe.medi_specify = null;
      this.prescribe.frequency_name = null;
      this.prescribe.strength = null;
      this.prescribe.duration = null;
      this.prescribe.directions = null;
      this.prescribe.quantity = null;
    },
  },
};
</script>
