<template>
  <div style="padding: 32px 0px 16px">
    <v-card flat v-resize="onResize">
      <div class="scrollable" :style="{ height: panheight }">
        <v-card-text>
          <v-overlay absolute dark :value="overlay"> </v-overlay>
          <v-row>
            <v-col cols="12" sm="6">
              <div class="mylist">
                <ul>
                  <li>
                    <div class="myleft">temperature</div>
                    <div class="myright">
                      {{ view.diag_temperature }}
                      {{ view.diag_temp_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">BP</div>
                    <div class="myright">
                      {{ view.diag_bp_diastolic }}/{{ view.diag_bp_diastolic }}
                      {{ view.diag_bp_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Pulse</div>
                    <div class="myright">
                      {{ view.diag_pulse }} {{ view.diag_pulse_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Weight</div>
                    <div class="myright">
                      {{ view.diag_weight }}
                      {{ view.diag_weight_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Height</div>
                    <div class="myright">
                      {{ view.diag_height }}
                      {{ view.diag_height_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Respiratory Rate</div>
                    <div class="myright">
                      {{ view.diag_respiratory_rate }}
                      {{ view.diag_respiratory_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Step Count</div>
                    <div class="myright">
                      {{ view.diag_steps_count }}
                      {{ view.diag_steps_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="mylist">
                <ul>
                  <li>
                    <div class="myleft">oxygen saturation</div>
                    <div class="myright">
                      {{ view.diag_oxy_saturation }}
                      {{ view.diag_oxy_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">visual acuity left</div>
                    <div class="myright">
                      {{ view.diag_visual_acuity_left }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">visual acuity right</div>
                    <div class="myright">
                      {{ view.diag_visual_acuity_right }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">occipital circum</div>
                    <div class="myright">
                      {{ view.diag_occipital_circum }}
                      {{ view.diag_occipital_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">mid upper (MUAC)</div>
                    <div class="myright">
                      {{ view.diag_mid_upper }}
                      {{ view.diag_muac_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">Wear glasses</div>
                    <div class="myright">
                      {{ view.diag_with_glasses ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                  <li>
                    <div class="myleft">intra occula pressure</div>
                    <div class="myright">
                      {{ view.diag_intra_occula_pressure }}
                      {{ view.diag_occipital_measureName }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                </ul>
              </div>
            </v-col>
          </v-row>
          <div>
            <div style="padding-left: 5px; margin-top: 25px">
              <v-card outlined flat>
                <v-card-title class="caption">Other</v-card-title>
                <v-card-text>
                  {{ view.diag_other }}
                </v-card-text>
              </v-card>
            </div>
          </div>
        </v-card-text>
      </div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon color="info" v-on="on" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh data</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import Restful from "@/services/RestFul";
import Util from "@/mixins/utils";
export default {
  props: { id: { type: String } },
  mixins: [Util],
  data: () => ({ overlay: true, view: {} }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 380 + "px";
      return h;
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.overlay = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.diagnostics
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.view = response.data;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    refresh() {
      this.find();
    },
  },
};
</script>
