<template>
  <div>
    <v-overlay :value="overlay" absolute dark></v-overlay>
    <v-card flat>
      <v-card-text>
        <v-tabs v-model="myLabs">
          <v-tab key="exam">examination findings</v-tab>
          <v-tab key="summary">summary of exam findings</v-tab>
        </v-tabs>
        <v-tabs-items v-model="myLabs">
          <v-tab-item key="exam" style="padding-top: 20px">
            <v-card flat v-resize="onResize">
              <div class="scrollable" :style="{ height: panheight }">
                <v-card-text>
                  <vue-editor
                    v-model="create.exam_findings"
                    placeholder="Examination Findings"
                  ></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item key="summary" style="padding-top: 20px">
            <v-card flat v-resize="onResize">
              <div class="scrollable" :style="{ height: panheight }">
                <v-card-text>
                  <vue-editor
                    v-model="create.summary"
                    placeholder="Summary of exam findings"
                  ></vue-editor>
                </v-card-text>
              </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="$v.create.$invalid"
          color="primary"
          text
          @click="submit"
        >
          submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Utils from "@/mixins/utils";
import Measurements from "../../../../mixins/measurements";
import { VueEditor } from "vue2-editor";

export default {
  props: { id: { type: String } },
  mixins: [Utils, Measurements, validationMixin],
  components: {
    VueEditor,
  },
  data: () => ({
    myLabs: null,
    menu2: null,
    overlay: false,
    myId: null,
    create: {
      exam_findings: null,
      summary: null,
    },
  }),
  validations: {
    create: { exam_findings: { required } },
  },
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 425 + "px";
      return h;
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.overlay = true;
      console.log(this._decode(this.id));
      Restful.medics.treatment.examfind
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.myId = response.data.id;
            this.create.exam_findings = response.data.exam_findings;
            this.create.summary = response.data.summary;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit() {
      let exam_findings = this.create.exam_findings;
      let summary = this.create.summary;

      let data = {
        exam_findings,
        summary,
      };

      let id = this.myId;
      let payload = { id: id, data: data };
      console.log("payload", payload);
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
