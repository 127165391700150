<template>
  <div style="padding: 7px 0px 16px">
    <v-overlay absolute dark :value="overlay"> </v-overlay>
    <v-tabs v-model="myHistory">
      <v-tab key="complaints">complaints</v-tab>
      <v-tab key="systemic">systemic enquiry</v-tab>
      <v-tab key="notes">Notes</v-tab>
      <v-tab key="summary_history">summary_history</v-tab>
    </v-tabs>

    <v-tabs-items v-model="myHistory">
      <v-tab-item key="complaints" style="padding-top: 20px">
        <v-card flat v-resize="onResize">
          <div class="scrollable" :style="{ height: panheight }">
            <v-card-text>
              <div style="padding-left: 5px">
                <v-card outlined flat>
                  <v-card-title class="caption">
                    Presenting Complaints
                  </v-card-title>
                  <v-card-text>
                    <ul class="myDataList">
                      <li
                        v-for="(item, index) in newLineToList(
                          view.main_problem
                        )"
                        :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </div>

              <div style="padding-left: 5px; margin: 5px 0 25px">
                <v-card outlined flat>
                  <v-card-title class="caption">
                    History of presenting complaints
                  </v-card-title>
                  <v-card-text>
                    {{ view.historic_complaint }}
                  </v-card-text>
                </v-card>
              </div>

              <div class="mylist">
                <ul>
                  <li>
                    <div class="myleft">drugs</div>
                    <div
                      class="myright"
                      :style="{ color: view.drugs ? 'blue' : 'red' }"
                    >
                      {{ view.drugs ? "Yes" : "No" }}
                    </div>
                    <div class="clearall"></div>
                  </li>
                </ul>
              </div>
              <div style="padding-left: 5px; margin: 7px 0px">
                <v-card outlined flat>
                  <v-card-title class="caption">drug details</v-card-title>
                  <v-card-text>
                    <ul class="myDataList">
                      <li
                        v-for="(item, index) in newLineToList(
                          view.drug_details
                        )"
                        :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="systemic" style="padding-top: 20px">
        <v-card
          v-resize="onResize"
          flat
          class="dyna_form_data"
          style="padding-bottom: 12px"
        >
          <div class="scrollable" :style="{ height: panheight }">
            <v-card-text>
              <v-row>
                <v-col
                  v-for="n in dataList"
                  :key="n.id"
                  class="pa-2"
                  outlined
                  tile
                >
                  <v-card style="min-width: 280px" outlined>
                    <v-toolbar
                      flat
                      dense
                      color="rgb(245 245 245)"
                      style="height: 35px"
                    >
                      <v-toolbar-title style="height: 35px">
                        {{ n.name }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <ul v-for="u in n.details" :key="u.id">
                        <li class="capitalize" style="padding-bottom: 4px">
                          <v-icon :color="u.active ? 'blue' : 'red'">
                            {{ u.active ? "mdi-check" : "mdi-home" }}
                          </v-icon>
                          {{ u.name }}
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="notes" style="padding-top: 20px">
        <v-card flat v-resize="onResize">
          <div class="scrollable" :style="{ height: panheight }">
            <v-card-text>
              <div style="padding-left: 5px">
                <v-card outlined flat>
                  <v-card-title class="caption"> notes </v-card-title>
                  <v-card-text>
                    <div v-html="view.medic_notes"></div>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="summary_history" style="padding-top: 20px">
        <v-card flat v-resize="onResize">
          <div class="scrollable" :style="{ height: panheight }">
            <v-card-text>
              <div style="padding-left: 5px">
                <v-card outlined flat>
                  <v-card-title class="caption">
                    summary of history
                  </v-card-title>
                  <v-card-text>
                    <div v-html="view.summary_history"></div>
                  </v-card-text>
                </v-card>
              </div>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-card flat>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon color="info" v-on="on" @click="refresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>Refresh data</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import Restful from "@/services/RestFul";
import Util from "@/mixins/utils";
export default {
  props: { id: { type: String } },
  mixins: [Util],
  data: () => ({
    overlay: true,
    view: {},
    dataList: [],
    myHistory: null,
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 425 + "px";
      return h;
    },
  },
  created() {
    this.find();
    this.findSystemic();
  },
  methods: {
    find() {
      this.overlay = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.history
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.view = response.data;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    findSystemic() {
      this.overlay = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.enquiry
        .active(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.dataList = response.data;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    refresh() {
      this.find();
    },
  },
};
</script>
