<template>
  <div style="padding: 0px 0px 16px">
    <v-card-text>
      <!-- <v-overlay absolute dark :value="overlay"> </v-overlay> -->

      <v-tabs v-model="myDiag">
        <v-tab key="diagnosis">diagnosis</v-tab>
        <v-tab key="prescription">prescription</v-tab>
        <v-spacer></v-spacer>
        <v-btn text fab color="primary" @click="refresh">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <!-- <v-tab key="other">other </v-tab> -->
      </v-tabs>
      <v-tabs-items v-model="myDiag">
        <v-tab-item key="diagnosis" style="padding: 10px 0 0 0">
          <v-card flat tile v-resize="onResize" style="padding: 0">
            <div class="scrollable" :style="{ height: panheight }">
              <div class="cid" style="margin: 0 7px">
                <v-card flat color="rgb(233 240 246)">
                  <v-card-title>Final Diagnosis</v-card-title>
                  <v-card-text>
                    <v-card flat style="background-color: white !important">
                      <v-card-actions
                        style="padding: 10px 16px; color: #91852b"
                      >
                        Principal Diagnosis
                        <v-spacer></v-spacer>
                        ICD 11 diagnosis
                      </v-card-actions>
                      <v-divider></v-divider>
                      <v-card-text style="padding: 0 6px 10px !important">
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                style="display: inline"
                                v-html="who.principal.title"
                              >
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                {{ who.principal.theCode }}
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action
                              style="font-size: 1.5em !important"
                            >
                              {{ who.principal.theCode }}
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                    <br />
                    <v-card outlined>
                      <v-card-actions
                        style="padding: 10px 16px; color: #91852b"
                      >
                        Other Diagnosis
                        <v-spacer></v-spacer>
                        find ICD 11 diagnosis
                      </v-card-actions>
                      <v-divider></v-divider>
                      <v-card-text
                        style="padding: 0 16px 10px !important"
                        v-show="who.other.length > 0"
                      >
                        <v-list
                          dense
                          v-for="(a, index) in who.other"
                          :key="index"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                style="display: inline"
                                v-html="a.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="a.theCode"
                              ></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action
                              v-text="a.theCode"
                            ></v-list-item-action>
                          </v-list-item>
                          <v-divider
                            v-if="index < who.other.length - 1"
                            :key="index"
                          ></v-divider>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>
                <br />
                <v-card outlined>
                  <v-card-title style="color: #91852b">
                    Provisional Diagnosis
                  </v-card-title>
                  <v-card-text v-html="who.free_text" style="padding: 0">
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item key="prescription" style="padding-top: 10px">
          <v-card flat>
            <v-card-text style="padding: 0px">
              <v-data-table
                dense
                :headers="headers"
                :items="who.prescriptions"
                :items-per-page="10"
              >
                <template v-slot:item.actions="{ item }">
                  {{ item.actions }}

                  <v-btn icon color="error" @click="remove(item)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>

    <v-snackbar
      :color="snack.color"
      v-model="snack.bar"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import Restful from "@/services/RestFul";
import Util from "@/mixins/utils";
export default {
  props: { id: { type: String } },
  mixins: [Util],
  data: () => ({
    editDialog: {},
    removeDialog: {},
    viewDialog: {},
    myDiag: null,
    moreDialog: false,
    overlay: true,
    view: [],
    who: {
      myId: 0,
      medics_available_timesid: 0,
      principal: {},
      other: [],
      prescriptions: [],
      free_text: null,
    },
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    headers: [
      { text: "medicine", value: "medicine_name" },
      { text: "brand", value: "medi_specify" },
      { text: "route", value: "route_name" },
      { text: "frequency", value: "frequency_name_name" },
      { text: "strength", value: "strength" },
      { text: "duration", value: "duration" },
      { text: "directions", value: "directions" },
      { text: "quantity", value: "quantity" },
      // { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 420 + "px";
      return h;
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.overlay = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.prescription
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.view = response.data;
            this.who.myId = response.data.id;
            this.who.medics_available_timesid =
              response.data.medics_available_timesid;
            this.who.principal = response.data.principal;
            this.who.other = response.data.other;
            this.who.prescriptions = response.data.prescriptions;
            this.who.free_text = response.data.free_text;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    refresh() {
      this.find();
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      console.log(p);
      this.snack.bar = true;
      Restful.medics.treatment.prescription
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.find();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.editDialog, p.id, p.state);
    },
    closeRemove(p) {
      this.$set(this.removeDialog, p.id, p.state);
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log("in", p);
      console.log("data", JSON.stringify(p.data));
      let self = this;
      Restful.medics.treatment.prescription
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.find();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.$set(this.removeDialog, p.id, p.state);
    },
    closeView(p) {
      this.$set(this.viewDialog, p.id, p.state);
    },
    closeMore(p) {
      this.moreDialog = p.state;
    },
    submitMore(p) {
      this.snack.bar = true;
      // console.log("in", p);
      let self = this;
      Restful.medics.treatment.prescription
        .add(p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.find();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.moreDialog = p.state;
    },
  },
};
</script>
