<template>
  <div v-resize="onResize">
    <v-row>
      <v-col cols="12" :sm="pane.record">
        <v-toolbar dense flat style="margin-bottom: 4px">
          <v-toolbar-title>
            {{ name | capitalize }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon @click="refresh" v-on="on">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh List</span>
          </v-tooltip>
        </v-toolbar>

        <v-card
          :style="{ maxHeight: panheight }"
          class="scrollable"
          flat
          :loading="sideLoad"
          loader-height="1"
        >
          <v-overlay :value="datesOverlay" absolute dark></v-overlay>
          <v-card-text style="padding: 0">
            <v-list dense rounded>
              <v-list-item-group v-model="selectedDate" color="primary">
                <v-list-item
                  v-for="item in myVisits"
                  :key="item.patient_request_id"
                  @click="detail(item.medics_available_times_id)"
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.schedule_time_start | myDating }} -
                      {{ item.schedule_time_end | myDateTiming }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ item.salute }} {{ item.first_name }}
                      {{ item.last_name }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-icon>mdi-menu-right</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" :sm="pane.main" v-show="pane.paneView">
        <v-toolbar loader-height="1" dense flat style="margin-bottom: 4px">
          <v-toolbar-title>
            <v-breadcrumbs :items="myCrumbs" style="padding: 0">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  divider="mdi-minus"
                  :href="item.href"
                  :disabled="item.disabled"
                >
                  {{ item.text.toUpperCase() }}
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon @click="refresh" v-on="on">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Refresh Data</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" icon @click="cancelDetail" v-on="on">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-card
          :loading="loading"
          loader-height="1"
          :style="{ height: panheight }"
          class="scrollable"
          flat
        >
          <v-overlay :value="overlay" absolute dark></v-overlay>

          <v-card-text class="summary_data">
            <div id="vitals">
              <v-card outlined flat>
                <v-card-text style="padding: 0 16px 16px">
                  <h2>Vitals</h2>
                  <div class="datalist">
                    <ul>
                      <li>
                        <div class="myleft">temperature</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_temperature }}
                          {{ findings.myVitals.diag_temp_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">BP</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_bp_diastolic }}/{{
                            findings.myVitals.diag_bp_diastolic
                          }}
                          {{ findings.myVitals.diag_bp_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Pulse</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_pulse }}
                          {{ findings.myVitals.diag_pulse_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Weight</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_weight }}
                          {{ findings.myVitals.diag_weight_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Height</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_height }}
                          {{ findings.myVitals.diag_height_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Respiratory Rate</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_respiratory_rate }}
                          {{ findings.myVitals.diag_respiratory_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Step Count</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_steps_count }}
                          {{ findings.myVitals.diag_steps_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">oxygen saturation</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_oxy_saturation }}
                          {{ findings.myVitals.diag_oxy_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">visual acuity left</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_visual_acuity_left }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">visual acuity right</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_visual_acuity_right }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">occipital circum</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_occipital_circum }}
                          {{ findings.myVitals.diag_occipital_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">mid upper (MUAC)</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_mid_upper }}
                          {{ findings.myVitals.diag_muac_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">Wear glasses</div>
                        <div class="myright">
                          {{
                            findings.myVitals.diag_with_glasses ? "Yes" : "No"
                          }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                      <li>
                        <div class="myleft">intra occula pressure</div>
                        <div class="myright">
                          {{ findings.myVitals.diag_intra_occula_pressure }}
                          {{ findings.myVitals.diag_occipital_measureName }}
                        </div>
                        <div class="clearall"></div>
                      </li>
                    </ul>
                  </div>

                  <v-card outlined flat>
                    <v-card-title class="caption">Other</v-card-title>
                    <v-card-text>
                      {{ findings.myVitals.diag_other }}
                    </v-card-text>
                  </v-card>
                </v-card-text>
              </v-card>
            </div>

            <div id="history">
              <v-card outlined flat style="margin-top: 10px">
                <v-card-text style="padding: 0 16px 16px">
                  <h2>history</h2>

                  <v-tabs v-model="tabs.myHistory" centered>
                    <v-tab key="complaints">complaints</v-tab>
                    <v-tab key="systemic">systemic enquiry</v-tab>
                    <v-tab key="notes">Notes</v-tab>
                    <v-tab key="summary_history">summary history</v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tabs.myHistory">
                    <v-tab-item key="complaints">
                      <v-card flat>
                        <v-card-text>
                          <v-card outlined flat>
                            <v-card-title class="caption">
                              Presenting Complaints
                            </v-card-title>
                            <v-card-text>
                              <ul class="myDataList">
                                <li
                                  v-for="(item, index) in newLineToList(
                                    findings.myHistory.main_problem
                                  )"
                                  :key="index"
                                >
                                  {{ item }}
                                </li>
                              </ul>
                            </v-card-text>
                          </v-card>

                          <div style="margin: 5px 0 25px">
                            <v-card outlined flat>
                              <v-card-title class="caption">
                                History of presenting complaints
                              </v-card-title>
                              <v-card-text>
                                {{ findings.myHistory.historic_complaint }}
                              </v-card-text>
                            </v-card>
                          </div>

                          <div class="mylist">
                            <ul>
                              <li>
                                <div class="myleft">drugs</div>
                                <div
                                  class="myright"
                                  :style="{
                                    color: findings.myHistory.drugs
                                      ? 'blue'
                                      : 'red',
                                  }"
                                >
                                  {{ findings.myHistory.drugs ? "Yes" : "No" }}
                                </div>
                                <div class="clearall"></div>
                              </li>
                            </ul>
                          </div>

                          <div style="padding-left: 5px; margin: 7px 0px">
                            <v-card outlined flat>
                              <v-card-title class="caption">
                                drug details
                              </v-card-title>
                              <v-card-text>
                                <ul class="myDataList">
                                  <li
                                    v-for="(item, index) in newLineToList(
                                      findings.myHistory.drug_details
                                    )"
                                    :key="index"
                                  >
                                    {{ item }}
                                  </li>
                                </ul>
                              </v-card-text>
                            </v-card>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="systemic">
                      <!-- starts -->
                      <v-card flat style="margin-top: 20px">
                        <v-card-text>
                          <v-row>
                            <v-col
                              v-for="n in findings.systemic"
                              :key="n.id"
                              class="pa-2"
                              outlined
                              tile
                            >
                              <v-card outlined>
                                <v-toolbar
                                  flat
                                  dense
                                  color="rgb(245 245 245)"
                                  style="height: 35px"
                                >
                                  <v-toolbar-title style="height: 35px">
                                    {{ n.name }}
                                  </v-toolbar-title>
                                </v-toolbar>
                                <v-card-text>
                                  <ul v-for="u in n.details" :key="u.id">
                                    <li
                                      class="capitalize"
                                      style="padding-bottom: 4px"
                                    >
                                      <v-icon
                                        :color="u.active ? 'blue' : 'red'"
                                      >
                                        {{
                                          u.active ? "mdi-check" : "mdi-home"
                                        }}
                                      </v-icon>
                                      {{ u.name }}
                                    </li>
                                  </ul>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                      <!-- ends -->
                    </v-tab-item>
                    <v-tab-item key="notes">
                      <v-card flat>
                        <v-card-text v-html="findings.myHistory.medic_notes">
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="summary_history">
                      <v-card flat>
                        <v-card-text
                          v-html="findings.myHistory.summary_history"
                        >
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </div>

            <div id="exams">
              <v-card outlined flat style="margin-top: 10px">
                <v-card-text style="padding: 0 16px 16px">
                  <h2>exams</h2>

                  <v-tabs v-model="tabs.exams">
                    <v-tab key="exam">examination findings</v-tab>
                    <v-tab key="summary">summary of exam findings</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs.exams">
                    <v-tab-item key="exam" style="padding-top: 20px">
                      <div v-html="findings.myExams.exam_findings"></div>
                    </v-tab-item>
                    <v-tab-item key="summary" style="padding-top: 20px">
                      <div v-html="findings.myExams.summary"></div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-card-text>
              </v-card>
            </div>

            <div id="diagnosis">
              <v-card outlined flat style="margin-top: 10px">
                <v-card-text style="padding: 0 16px 16px">
                  <h2>diagnosis</h2>

                  <!-- startssss -->
                  <v-tabs v-model="tabs.myDiag">
                    <v-tab key="diagnosis">diagnosis</v-tab>
                    <v-tab key="prescription">prescription</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs.myDiag">
                    <v-tab-item key="diagnosis" style="padding: 10px 0 0 0">
                      <v-card flat tile v-resize="onResize" style="padding: 0">
                        <div class="cid" style="margin: 0 7px">
                          <v-card flat color="rgb(233 240 246)">
                            <v-card-title>Final Diagnosis</v-card-title>
                            <v-card-text>
                              <v-card
                                flat
                                style="background-color: white !important"
                              >
                                <v-card-actions
                                  style="padding: 10px 16px; color: #91852b"
                                >
                                  Principal Diagnosis
                                  <v-spacer></v-spacer>
                                  ICD 11 diagnosis
                                </v-card-actions>
                                <v-divider></v-divider>
                                <v-card-text
                                  style="padding: 0 6px 10px !important"
                                >
                                  <v-list>
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          style="display: inline"
                                        >
                                          {{ findings.diagPrincipal.title }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                          {{ findings.diagPrincipal.theCode }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>

                                      <v-list-item-action
                                        style="font-size: 1.5em !important"
                                      >
                                        {{ findings.diagPrincipal.theCode }}
                                      </v-list-item-action>
                                    </v-list-item>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                              <br />
                              <v-card outlined>
                                <v-card-actions
                                  style="padding: 10px 16px; color: #91852b"
                                >
                                  Other Diagnosis
                                  <v-spacer></v-spacer>
                                  find ICD 11 diagnosis
                                </v-card-actions>
                                <v-divider></v-divider>
                                <v-card-text
                                  style="padding: 0 16px 10px !important"
                                  v-show="findings.diagOther.length > 0"
                                >
                                  <v-list
                                    dense
                                    v-for="(a, index) in findings.diagOther"
                                    :key="index"
                                  >
                                    <v-list-item>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          style="display: inline"
                                          v-html="a.title"
                                        ></v-list-item-title>
                                        <v-list-item-subtitle
                                          v-text="a.theCode"
                                        ></v-list-item-subtitle>
                                      </v-list-item-content>

                                      <v-list-item-action
                                        v-text="a.theCode"
                                      ></v-list-item-action>
                                    </v-list-item>
                                    <v-divider
                                      v-if="
                                        index < findings.diagOther.length - 1
                                      "
                                      :key="index"
                                    ></v-divider>
                                  </v-list>
                                </v-card-text>
                              </v-card>
                            </v-card-text>
                          </v-card>
                          <br />
                          <v-card outlined>
                            <v-card-title style="color: #91852b">
                              Provisional Diagnosis
                            </v-card-title>
                            <v-card-text
                              v-html="findings.diagnosis.free_text"
                              style="padding: 0"
                            >
                            </v-card-text>
                          </v-card>
                        </div>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="prescription" style="padding-top: 10px">
                      <v-card flat>
                        <v-card-text style="padding: 0px">
                          <v-data-table
                            dense
                            :headers="headers"
                            :items="findings.diagPrescribe"
                            :items-per-page="10"
                          >
                            <template v-slot:item.actions="{ item }">
                              {{ item.actions }}

                              <v-btn icon color="error" @click="remove(item)">
                                <v-icon>mdi-delete-outline</v-icon>
                              </v-btn>
                            </template>
                          </v-data-table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <!-- endsssss -->
                </v-card-text>
              </v-card>
            </div>

            <div id="investigate">
              <v-card outlined flat style="margin-top: 10px">
                <v-card-text style="padding: 0 16px 16px">
                  <h2>investigate</h2>
                  <!-- startsssssssss -->
                  <v-tabs v-model="tabs.myLabs">
                    <v-tab key="general">laboratory request</v-tab>
                    <v-tab key="imaging">imaging request</v-tab>
                    <v-tab key="other">other request</v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabs.myLabs">
                    <v-tab-item key="general" style="padding-top: 20px">
                      <v-card flat>
                        <v-card-text style="padding: 0 6px">
                          <v-card outlined style="margin-bottom: 20px">
                            <v-card-text style="padding: 16px !important">
                              <v-card flat>
                                <v-card-text style="padding: 0 6px">
                                  <v-row>
                                    <v-col cols="12" sm="6">
                                      <div class="data_text">
                                        <ul>
                                          <li>
                                            <div class="my_title">
                                              drug therapy
                                            </div>
                                            <div class="my_text">
                                              {{
                                                findings.laboratory.drug_therapy
                                              }}
                                            </div>
                                          </li>
                                          <li>
                                            <div class="my_title">fasting</div>
                                            <div
                                              class="my_text"
                                              :style="{
                                                color: findings.laboratory
                                                  .fasting
                                                  ? 'blue'
                                                  : 'red',
                                              }"
                                            >
                                              {{
                                                findings.laboratory.fasting
                                                  ? "Yes"
                                                  : "No"
                                              }}
                                            </div>
                                          </li>
                                          <li>
                                            <div class="my_title">urgency</div>
                                            <div
                                              class="my_text"
                                              :style="{
                                                color: findings.laboratory
                                                  .urgency
                                                  ? 'blue'
                                                  : 'red',
                                              }"
                                            >
                                              {{
                                                findings.laboratory.urgency
                                                  ? "Yes"
                                                  : "No"
                                              }}
                                            </div>
                                          </li>
                                          <li>
                                            <div class="my_title">
                                              last taken
                                            </div>
                                            <div class="my_text">
                                              {{
                                                findings.laboratory
                                                  .last_dose_date_time | myDate
                                              }}
                                            </div>
                                          </li>
                                          <li>
                                            <div class="my_title">
                                              clinical information
                                            </div>
                                            <div class="my_text">
                                              {{
                                                findings.laboratory
                                                  .clinical_info
                                              }}
                                            </div>
                                          </li>
                                          <li>
                                            <div class="my_title">accepted</div>
                                            <div
                                              class="my_text"
                                              :style="{
                                                color: findings.laboratory
                                                  .accepted
                                                  ? 'blue'
                                                  : 'red',
                                              }"
                                            >
                                              {{
                                                findings.laboratory.accepted
                                                  ? "Yes"
                                                  : "No"
                                              }}
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    </v-col>
                                    <v-col cols="12" sm="6">
                                      <v-card outlined>
                                        <v-card-text>
                                          <div class="data_text">
                                            <ul>
                                              <li>
                                                <div
                                                  class="my_title"
                                                  style="font-size: 0.8em"
                                                >
                                                  other instructions
                                                </div>
                                                <div class="my_text">
                                                  {{
                                                    findings.laboratory
                                                      .profile_test
                                                  }}
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-card-text>
                          </v-card>

                          <v-card
                            class="pa-2 dyna_form_data"
                            outlined
                            style="margin-bottom: 20px"
                            v-for="item in findings.specimens"
                            :key="item.id"
                          >
                            <v-toolbar dense flat color="rgb(245, 245, 245)">
                              <v-toolbar-title>
                                {{ item.name }}
                              </v-toolbar-title>
                            </v-toolbar>
                            <v-card-text style="padding: 20px 0 10px">
                              <v-row>
                                <v-col
                                  v-for="d in item.detail"
                                  :key="d.id"
                                  outlined
                                  class="pa-2"
                                  tile
                                >
                                  <v-card
                                    outlined
                                    style="
                                      min-width: 180px;
                                      padding-bottom: 10px;
                                      margin: 16px 0;
                                    "
                                  >
                                    <v-card-title>{{ d.name }}</v-card-title>
                                    <v-card-text>
                                      <ul v-for="u in d.units" :key="u.id">
                                        <li>
                                          <v-icon
                                            :color="u.active ? 'blue' : 'red'"
                                          >
                                            {{
                                              u.active
                                                ? "mdi-check"
                                                : "mdi-home"
                                            }}
                                          </v-icon>
                                          {{ u.name }}
                                        </li>
                                      </ul>
                                    </v-card-text>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <v-tab-item key="imaging" style="padding-top: 20px">
                      <div
                        class="listos"
                        v-html="findings.laboratory.imaging"
                      ></div>
                    </v-tab-item>
                    <v-tab-item key="other" style="padding-top: 20px">
                      <div
                        class="listos"
                        v-html="findings.laboratory.other_specimens"
                      ></div>
                    </v-tab-item>
                  </v-tabs-items>
                  <!-- endsssssssssss -->
                </v-card-text>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../../../mixins/utils";

export default {
  props: {
    panh: { type: Number },
    id: { type: String },
    name: { type: String },
    patid: { type: String },
  },
  components: {},
  mixins: [Util],
  data: () => ({
    windowSize: {},
    overlay: false,
    datesOverlay: false,
    sideLoad: false,
    loading: false,
    pane: {
      record: 12,
      main: 0,
      paneView: false,
    },
    tabs: {
      myHistory: null,
      exams: null,
      myDiag: null,
      myLabs: null,
    },
    myCrumbs: [
      {
        text: "Vitals",
        disabled: false,
        href: "#vitals",
      },
      {
        text: "History",
        disabled: false,
        href: "#history",
      },
      {
        text: "Examination Findings",
        disabled: false,
        href: "#exams",
      },
      {
        text: "Diagnosis And Prescription",
        disabled: false,
        href: "#diagnosis",
      },
      {
        text: "Investigations",
        disabled: false,
        href: "#investigate",
      },
    ],
    findings: {
      myVitals: {},
      myHistory: {},
      myExams: {},
      diagnosis: {},
      diagPrincipal: {},
      diagOther: [],
      diagPrescribe: [],
      systemic: [],
      laboratory: {},
      specimens: [],
    },
    myVisits: [],
    selectedDate: null,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    details: null,
    headers: [
      { text: "route", value: "route_name" },
      { text: "medicene", value: "pharma_categoryid_name" },
      { text: "preferred brand", value: "medi_specify" },
      { text: "strength", value: "strength" },
      { text: "frequency", value: "frequency_name_name" },
      { text: "duration", value: "duration" },
      { text: "directions", value: "directions" },
      { text: "quantity", value: "quantity" },
    ],
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 280 + "px";
      return h;
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.datesOverlay = true;
      this.sideLoad = true;
      let self = this;
      let id = parseInt(this._decode(this.patid));
      let data = { id };
      console.log("data", data);
      Restful.medics.treatment.summary
        .list(data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.myVisits = response.data;
          }
          this.sideLoad = false;
          this.datesOverlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.sideLoad = false;
          self.datesOverlay = false;
        });
    },
    detail(medAvLid) {
      this.loading = true;
      console.log(medAvLid);
      this.details = medAvLid;
      this.pane.record = 4;
      this.pane.main = 8;
      this.pane.paneView = true;
      this.findHistory(medAvLid);
      this.loading = false;
    },
    findHistory(medavid) {
      let id = parseInt(medavid);
      let data = { id };
      Restful.medics.treatment.summary
        .find(data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.findings.myVitals = response.data.vitals;
            this.findings.myHistory = response.data.history;
            this.findings.myExams = response.data.exams;
            this.findings.diagnosis = response.data.diagnosis;
            this.findings.diagPrincipal = response.data.diagnosis.principal;
            this.findings.diagOther = response.data.diagnosis.other;
            this.findings.diagPrescribe = response.data.diagnosis.prescriptions;
            this.findings.systemic = response.data.systemic;
            this.findings.laboratory = response.data.laboratory;
            this.findings.specimens = response.data.laboratory.specimens;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    cancelDetail() {
      this.pane.record = 12;
      this.pane.main = 0;
      this.pane.paneView = false;
    },
    refresh() {
      this.list();
    },
    onResize() {
      // console.log(a);
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
  },
};
</script>
