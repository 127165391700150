// import moment from "moment";
import Restful from "@/services/RestFul";

export default {
  data() {
    return {
      mylengthlist: [],
      myArealist: [],
      myVolumelist: [],
      myMasslist: [],
      myTemperaturelist: [],
      myPowerlist: [],
      myPressurelist: [],
      myThermalist: [],
      myTimelist: [],
      myCounting: [],
    };
  },
  created() {
    this.mylength();
    this.myArea();
    this.myVolume();
    this.myMass();
    this.myTemperature();
    this.myPowers();
    this.myPressure();
    this.myThermal();
    this.myTime();
    this.myCount();
  },
  methods: {
    mylength() {
      // let self = this;
      Restful.general.measurements
        .byMeasurementCategory(1)
        .then((response) => {
          // console.log(response);
          this.mylengthlist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myArea() {
      // let self = this;
      Restful.general.measurements
        .byMeasurementCategory(2)
        .then((response) => {
          // console.log(response);
          this.myArealist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myVolume() {
      // let self = this;
      Restful.general.measurements
        .byMeasurementCategory(3)
        .then((response) => {
          // console.log(response);
          this.myVolumelist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myMass() {
      Restful.general.measurements
        .byMeasurementCategory(4)
        .then((response) => {
          // console.log(response);
          this.myMasslist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myTemperature() {
      Restful.general.measurements
        .byMeasurementCategory(5)
        .then((response) => {
          // console.log(response);
          this.myTemperaturelist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myPowers() {
      Restful.general.measurements
        .byMeasurementCategory(6)
        .then((response) => {
          // console.log(response);
          this.myPowerlist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myPressure() {
      // let self = this;
      Restful.general.measurements
        .byMeasurementCategory(7)
        .then((response) => {
          // console.log(response);
          this.myPressurelist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myThermal() {
      // let self = this;
      Restful.general.measurements
        .byMeasurementCategory(5)
        .then((response) => {
          // console.log(response);
          this.myTemperaturelist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myTime() {
      // let self = this;
      Restful.general.measurements
        .byMeasurementCategory(8)
        .then((response) => {
          // console.log(response);
          this.myTimelist = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    myCount() {
      // let self = this;
      Restful.general.measurements
        .byMeasurementCategory(9)
        .then((response) => {
          // console.log(response);
          this.myCounting = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
