<template>
  <div v-resize="onResize">
    <v-toolbar dense flat style="margin-bottom: 4px">
      <v-toolbar-title>
        {{ name | capitalize }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-show="isNew" color="primary" text x-small>new</v-btn>
      <v-btn v-show="!isNew" color="primary" text x-small @click="view">
        view
      </v-btn>
      <v-btn :disabled="isDisabled" color="success" text x-small @click="edit">
        edit
      </v-btn>
    </v-toolbar>
    <v-card
      :loading="loading"
      :style="{ height: panheight }"
      flat
      loader-height="1"
    >
      <div v-if="isNew === true">
        <v-overlay :value="overlay" absolute dark></v-overlay>
        <NewOne :id="id" @submitNew="submitNew" />
      </div>
      <div v-else>
        <div v-if="editNow === true">
          <EditData :id="id" @submitEdit="submitEdit" />
        </div>
        <div v-else-if="editNow === false">
          <ViewData :id="id" />
        </div>
      </div>
    </v-card>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "@/mixins/utils";
import NewOne from "../exams/newone.vue";
import ViewData from "../exams/view.vue";
import EditData from "../exams/edit.vue";

export default {
  props: {
    panh: { type: Number },
    id: { type: String },
    name: { type: String },
    patid: { type: String },
  },
  components: {
    NewOne,
    ViewData,
    EditData,
  },
  mixins: [Util],
  data: () => ({
    removeDialog: false,
    myId: 0,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    windowSize: {},
    overlay: true,
    loading: false,
    isPresent: false,
    isNew: true,
    isDisabled: true,
    editNow: false,
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 280 + "px";
      return h;
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      this.loading = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.examfind
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.appointmentlist = response.data;
            this.myId = response.data.id;
            if (response.data.id > 0) {
              this.isNew = false;
              this.isPresent = true;
              this.isDisabled = false;
            } else {
              this.isNew = true;
              this.isPresent = false;
              this.isDisabled = true;
            }
          } else {
            console.log("not good");
          }
          this.loading = false;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
          self.overlay = false;
          self.isPresent = false;
          self.isDisabled = true;
        });
    },
    refresh() {
      this.list();
    },
    view() {
      this.editNow = false;
    },
    edit() {
      this.editNow = true;
    },
    onResize() {
      // console.log(a);
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    submitNew(p) {
      this.snack.bar = true;
      console.log("p", p);
      let self = this;
      Restful.medics.treatment.examfind
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.list();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      console.log(p);
    },
    submitEdit(p) {
      console.log("in", p);
      this.snack.bar = true;
      let self = this;
      Restful.medics.treatment.examfind
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.list();
          this.editNow = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      console.log("p", p);
    },
  },
};
</script>
