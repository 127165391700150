<template>
  <div style="padding: 7px 0px 16px">
    <v-tabs v-model="myHistory">
      <v-tab key="complaints">complaints</v-tab>
      <v-tab key="systemic">systemic enquiry</v-tab>
      <v-tab key="notes">Notes</v-tab>
      <v-tab key="summary_history">summary of history</v-tab>
    </v-tabs>
    <v-tabs-items v-model="myHistory">
      <v-tab-item key="complaints" style="padding-top: 20px">
        <v-card flat v-resize="onResize">
          <div class="scrollable" :style="{ height: panheight }">
            <v-card-text>
              <v-overlay absolute dark :value="overlay"> </v-overlay>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-textarea
                    outlined
                    rows="5"
                    label="Presenting Complaints"
                    v-model="create.main_problem"
                    maxlength="250"
                    counter
                  ></v-textarea>
                  <v-textarea
                    outlined
                    rows="5"
                    label="History of presenting complaints"
                    v-model="create.historic_complaint"
                    maxlength="250"
                    counter
                  ></v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-switch
                    style="margin-top: 1px; padding-left: 4px"
                    v-model="create.drugs"
                    :label="`Are you on any medications: ${create.drugs}`"
                  ></v-switch>

                  <v-textarea
                    :disabled="create.drugs ? false : true"
                    outlined
                    rows="9"
                    label="medication history"
                    v-model="create.drug_details"
                    maxlength="500"
                    counter
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="systemic" style="padding-top: 20px" v-resize="onResize">
        <div class="scrollable" :style="{ height: panheight }">
          <v-card class="dyna_form_data" flat style="padding-bottom: 12px">
            <v-card-text>
              <v-row>
                <v-col
                  v-for="n in dataList"
                  :key="n.id"
                  class="pa-2"
                  outlined
                  tile
                >
                  <v-card style="min-width: 280px" outlined>
                    <v-toolbar
                      flat
                      dense
                      color="rgb(245 245 245)"
                      style="height: 35px"
                    >
                      <v-toolbar-title style="height: 35px">
                        {{ n.name }}
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                      <ul v-for="u in n.details" :key="u.id">
                        <li>
                          <v-checkbox
                            dense
                            hide-details
                            :id="parseInt(u.id)"
                            :value="u"
                            v-model="create.selectedDetail"
                            :label="`${u.name}`"
                          ></v-checkbox>
                        </li>
                      </ul>
                      <!-- <v-text-field
                      outlined
                      dense
                      label="comment"
                      :hint="n.description"
                      persistent-hint
                    ></v-text-field> -->
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- <p>{{ create.selectedDetail }}</p> -->
            </v-card-text>
          </v-card>
        </div>
      </v-tab-item>
      <v-tab-item key="notes" style="padding-top: 20px">
        <v-card flat v-resize="onResize">
          <div class="scrollable" :style="{ height: panheight }">
            <v-card-text>
              <vue-editor v-model="create.my_notes"></vue-editor>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
      <v-tab-item key="summary_history" style="padding-top: 20px">
        <v-card flat v-resize="onResize">
          <div class="scrollable" :style="{ height: panheight }">
            <v-card-text>
              <vue-editor v-model="create.summary_history"></vue-editor>
            </v-card-text>
          </div>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-card style="margin-top: 4px" flat>
      <v-card-actions>
        <v-btn
          text
          color="primary"
          @click="submit"
          :disabled="$v.create.$invalid"
        >
          save
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Utils from "@/mixins/utils";
import Measurements from "../../../../mixins/measurements";
export default {
  props: { id: { type: String } },
  components: {
    VueEditor,
  },
  mixins: [Utils, Measurements, validationMixin],
  data: () => ({
    overlay: false,
    myHistory: null,
    dataList: [],
    create: {
      myId: null,
      main_problem: null,
      drugs: false,
      drug_details: null,
      historic_complaint: null,
      selectedDetail: [],
      my_notes: null,
      summary_history: null,
    },
  }),
  validations: {
    create: {
      main_problem: {
        required,
      },
      drugs: {
        required,
      },
      my_notes: {
        required,
      },
      summary_history: {
        required,
      },
    },
  },
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 425 + "px";
      return h;
    },
  },
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      this.loading = true;
      console.log(this._decode(this.id));
      Restful.general.systemics
        .history()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.dataList = response.data;
          } else {
            this.dataList = [];
          }
          this.loading = false;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    submit() {
      let medics_available_timesid = parseInt(this._decode(this.id));
      let main_problem = this.create.main_problem;
      let drugs = this.create.drugs;
      let drug_details = this.create.drug_details;
      let historic_complaint = this.create.historic_complaint;
      let jsonData = this.create.selectedDetail;
      let medic_notes = this.create.my_notes;
      let summary_history = this.create.summary_history;

      let data = {
        medics_available_timesid,
        main_problem,
        drugs,
        drug_details,
        historic_complaint,
        jsonData,
        medic_notes,
        summary_history,
      };
      console.log(data);
      let payload = { data: data };
      this.$emit("submitNew", payload);
    },
  },
};
</script>
