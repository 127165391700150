<template>
  <v-card flat>
    <v-card-text>
      <v-tabs v-model="myDiag">
        <v-tab key="diagnosis">diagnosis</v-tab>
        <v-tab key="prescription">prescription</v-tab>
      </v-tabs>
      <v-tabs-items v-model="myDiag">
        <v-tab-item key="diagnosis" style="padding: 10px 0 0 0">
          <v-card flat tile v-resize="onResize" style="padding: 0">
            <div class="scrollable" :style="{ height: panheight }">
              <div class="cid" style="margin: 0 7px">
                <v-card flat color="rgb(233 240 246)">
                  <v-card-title>Final Diagnosis</v-card-title>
                  <v-card-text>
                    <v-card flat style="background-color: white !important">
                      <v-card-actions
                        style="padding: 10px 16px; color: #91852b"
                      >
                        Principal Diagnosis
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              v-on="on"
                              color="primary"
                              x-small
                              @click.stop="whoDialog = true"
                            >
                              find ICD 11 diagnosis
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            This allows only a single diagnosis. Repeat to
                            change or update your initial diagnosis.
                          </span>
                        </v-tooltip>
                        <WhoDialog
                          :whoDialog="whoDialog"
                          @closeWho="closeWho"
                          @submitWho="submitWho"
                        />
                      </v-card-actions>
                      <v-divider></v-divider>
                      <v-card-text
                        style="padding: 0 6px 10px !important"
                        v-show="who.principal !== null"
                      >
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                style="display: inline"
                                v-html="who.principal.title"
                              >
                              </v-list-item-title>
                              <v-list-item-subtitle
                                v-text="who.principal.theCode"
                              >
                              </v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action
                              style="font-size: 1.5em !important"
                              v-text="who.principal.theCode"
                            >
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>
                    <br />
                    <v-card outlined>
                      <v-card-actions
                        style="padding: 10px 16px; color: #91852b"
                      >
                        Other Diagnosis
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              text
                              v-on="on"
                              color="primary"
                              x-small
                              @click.stop="whotherDialog = true"
                            >
                              find ICD 11 diagnosis
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            This allows you to add as many as your diagnosis
                            will allow
                          </span>
                        </v-tooltip>
                        <WhotherDialog
                          :whotherDialog="whotherDialog"
                          @closeWhose="closeWhose"
                          @submitWhose="submitWhose"
                        />
                      </v-card-actions>
                      <v-divider></v-divider>
                      <v-card-text
                        style="padding: 0 16px 10px !important"
                        v-show="who.other.length > 0"
                      >
                        <v-list
                          dense
                          v-for="(a, index) in who.other"
                          :key="index"
                        >
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title
                                style="display: inline"
                                v-html="a.title"
                              ></v-list-item-title>
                              <v-list-item-subtitle
                                v-text="a.theCode"
                              ></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    outlined
                                    color="red"
                                    @click.stop="removeFromWhose(a)"
                                    icon
                                    x-small
                                  >
                                    <v-icon>mdi-minus</v-icon>
                                  </v-btn>
                                </template>
                                <span>Remove from list</span>
                              </v-tooltip>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider
                            v-if="index < whoseList.length - 1"
                            :key="index"
                          ></v-divider>
                        </v-list>
                      </v-card-text>
                    </v-card>
                  </v-card-text>
                </v-card>

                <br />
                <v-card outlined>
                  <v-card-title style="color: #91852b">
                    Provisional Diagnosis
                  </v-card-title>
                  <vue-editor
                    v-model="who.free_text"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                </v-card>
                <br />
              </div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item key="prescription" style="padding-top: 10px">
          <v-card flat>
            <v-card-title>
              <span style="color: rgb(124, 183, 13); font-weight: 400">
                Edit Prescription
              </span>
              <v-spacer></v-spacer>
              <v-btn
                text
                x-small
                color="success"
                @click.stop="addDialog = true"
              >
                add medication
              </v-btn>
              <PrescribeAdd
                :addDialog="addDialog"
                :myId="id"
                @closeAdd="closeAdd"
                @submitAdd="submitAdd"
              />
            </v-card-title>
            <v-card-text style="padding: 0px">
              <v-data-table
                dense
                :headers="headers"
                :items="who.prescriptions"
                :items-per-page="10"
              >
                <template v-slot:item.actions="{ item }">
                  {{ item.actions }}
                  <!-- <v-btn
                      icon
                      color="primary"
                      @click="$set(editDialog, item.id, true)"
                    >
                      <v-icon> mdi-circle-edit-outline </v-icon>
                    </v-btn>
                    <PrescribeEdit
                      v-if="editDialog[item.id]"
                      :editDialog="editDialog[item.id]"
                      :myId="item"
                      :id="item.id"
                      @closeEdit="closeEdit"
                      @submitEdit="submitEdit"
                    /> -->

                  <v-btn icon color="error" @click="remove(item)">
                    <v-icon>mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn text color="primary" @click.stop="submit"> save edit</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils";
import PrescribeAdd from "../../../../components/appointments/prescribe/add.vue";
// import PrescribeEdit from "../../../../components/appointments/prescribe/edit.vue";
import WhoDialog from "./whoDialog.vue";
import WhotherDialog from "./whotherDialog.vue";
import Measures from "../../../../mixins/measurements";
import { VueEditor } from "vue2-editor";

export default {
  props: { id: { type: String } },
  components: {
    VueEditor,
    PrescribeAdd,
    // PrescribeEdit,
    WhoDialog,
    WhotherDialog,
  },
  mixins: [Utils, Measures],
  data: () => ({
    overlay: false,
    addDialog: false,
    whoDialog: false,
    whotherDialog: false,
    icdata: {
      whoDdata: {},
      whoseData: {},
      freeText: null,
    },
    whoseList: [],
    selectedWhoseItem: null,
    myDiag: null,
    editDialog: {},
    pharmacatList: [],
    medicatList: [],
    getPresc: [],
    prescriptions: [],
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    headers: [
      { text: "medicine", value: "medicine_name" },
      { text: "brand", value: "medi_specify" },
      { text: "route", value: "route_name" },
      { text: "frequency", value: "frequency_name_name" },
      { text: "strength", value: "strength" },
      { text: "duration", value: "duration" },
      { text: "directions", value: "directions" },
      { text: "quantity", value: "quantity" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    who: {
      myId: 0,
      medics_available_timesid: 0,
      principal: {},
      other: [],
      prescriptions: [],
      free_text: null,
    },
    icd: {
      loading: false,
      searching: null,
      resultList: [],
      entity: {},
    },
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 420 + "px";
      return h;
    },
  },
  created() {
    this.finding();
    this.medicat();
    this.pharmacat();
  },
  methods: {
    finding() {
      this.overlay = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.prescription
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.view = response.data;
            this.who.myId = response.data.id;
            this.who.medics_available_timesid =
              response.data.medics_available_timesid;
            this.who.principal = response.data.principal;
            this.who.other = response.data.other;
            this.who.prescriptions = response.data.prescriptions;
            this.who.free_text = response.data.free_text;
          }
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    stemid(a) {
      console.log("a stemid:", a);
    },
    find(a) {
      let orig = a.toString();
      console.log("orig:", orig);
      // http://id.who.int/icd/release/11/2023-01/mms/1401866267
      let entity = orig.replaceAll(
        "http://id.who.int/icd/release/11/2023-01/mms/",
        ""
      );

      console.log("entity:", entity);
      let self = this;
      Restful.medics.treatment.diagnosis
        .entity(entity)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.icd.entity = response.data;
          }
          this.icd.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.icd.loading = false;
        });
    },
    getDifinition(a) {
      console.log("getDifinition a:", a);
    },
    closeWho(a) {
      this.whoDialog = a.state;
    },
    submitWho(a) {
      this.who.principal = a.data;
      console.log("data: ", this.icdata.whoDdata);
      this.whoDialog = a.state;
    },
    closeWhose(a) {
      this.whotherDialog = a.state;
    },
    submitWhose(a) {
      this.icdata.whoseData = a.data;
      this.who.other.push({
        id: this.icdata.whoseData.id,
        theCode: this.icdata.whoseData.theCode,
        title: this.icdata.whoseData.title,
      });

      console.log("data: ", this.icdata.whoseData);
      console.log("whoseList: ", JSON.stringify(this.whoseList));
      this.whotherDialog = a.state;
    },
    removeFromWhose(a) {
      console.log("a", a);
      this.who.other.splice(a, 1);
      console.log("this.whoseList", this.whoseList);
    },
    medicat() {
      Restful.general.medicat
        .list()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.medicatList = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    pharmacat() {
      Restful.general.pharmacat
        .list()
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.pharmacatList = response.data;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    add() {
      let me = this.$store.getters.getPresc.length + 1;
      let cat = this.prescribe.pharma_categoryid;

      this.create({
        id: me,
        pharma_categoryid: cat.id,
        catName: cat.drug + ", " + cat.formulation,
        medi_specify: this.prescribe.medi_specify,
        strength: this.prescribe.strength,
        quantity: this.prescribe.quantity,
        frequency: this.prescribe.frequency,
        refills: this.prescribe.refills,
      });
    },
    closeAdd(p) {
      this.addDialog = p.state;
    },
    submitAdd(p) {
      let data = p.data;
      console.log("data", data);

      let start = {
        medicine_name: data.medicine_name,
        medicine_id: data.medicine_id,
        medi_specify: data.medi_specify,
        route_name_id: data.route_name_id,
        route_name: data.route_name,
        frequency_name_id: data.frequency_name_id,
        frequency_name_name: data.frequency_name_name,
        strength: data.strength,
        duration: data.duration,
        directions: data.directions,
        quantity: data.quantity,
      };
      console.log("start", start);
      this.who.prescriptions.push(start);

      console.log("list", this.prescriptions);
      console.log("list", JSON.stringify(this.getPresc));

      this.addDialog = p.state;
    },
    closeEdit(p) {
      this.$set(this.editDialog, p.id, p.state);
    },
    submitEdit(p) {
      let data = p.data;
      console.log("payload", data);
      console.log("idd", p.id);

      this.editPres({
        id: p.id,
        medi_category: data.medi_category,
        medics_available_timesid: data.medics_available_timesid,
        pharma_categoryid: data.pharma_categoryid,
        catName: data.catName,
        medi_specify: data.medi_specify,
        strength: data.strength,
        quantity: data.quantity,
        frequency: data.frequency,
        refills: data.refills,
      });
      this.getPresc;
      this.$set(this.editDialog, p.id, p.state);
    },
    remove(a) {
      let index = this.who.prescriptions.indexOf(a);
      this.who.prescriptions.splice(index, 1);
      // console.log("this.getPresc", this.getPresc);
    },
    submit() {
      let id = this.who.myId;
      let freeText = this.who.free_text;
      let principal = this.who.principal;
      let other = this.who.other;
      let prescriptions = this.who.prescriptions;
      let data = {
        principal,
        other,
        freeText,
        prescriptions,
      };
      let payload = { data: data, id: id, state: false };
      this.$emit("submitEdit", payload);
    },
  },
};
</script>
