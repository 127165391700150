<template>
  <div class="page">
    <div class="heading">
      <v-toolbar :flat="flatapp" dense>
        <v-btn icon @click="menulink">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <v-breadcrumbs :items="crumbs" exact>
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon @click="goBack" v-on="on">
              <v-icon>mdi-keyboard-return</v-icon>
            </v-btn>
          </template>
          <span>Go Back</span>
        </v-tooltip>
      </v-toolbar>
      <v-divider></v-divider>
    </div>

    <div class="body">
      <v-toolbar dense flat>
        <v-toolbar-title>
          {{ _decode(name) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          :color="medTimeisDone ? 'success' : 'primary'"
          :disabled="medTimeisDone ? true : false"
          depressed
          x-small
          @click.stop="finishDialog = true"
        >
          {{ medTimeisDone ? "done" : "end-session" }}
          <v-icon color="white" large style="margin-left: 10px">
            {{ medTimeisDone ? "mdi-check-outline" : "mdi-window-close" }}
          </v-icon>
        </v-btn>
        <TreatFinished
          :finishDialog="finishDialog"
          :myId="patid"
          :name="name"
          @closeSession="closeSession"
          @submitSession="submitSession"
        />

        <v-btn icon @click="refresh()">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card
        class="d-flex flex-wrap scrollable"
        color="grey lighten-2"
        flat
        tile
      >
        <v-card
          v-for="i in treatmentList"
          :key="i.step"
          class="flex-grow-1 flex-shrink-0"
          hover
          outlined
          tile
          @click="startPatient(i.step, i.name)"
        >
          <v-card-actions
            class="pa-2 treatactive"
            v-bind:style="{ color: activeColor }"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span class="capitalize" v-on="on" v-text="i.name"></span>
              </template>
              <span v-text="i.description"></span>
            </v-tooltip>
            <v-spacer></v-spacer>
            <v-icon :color="checking(i.step) ? 'blue' : 'red'" small>
              {{ checking(i.step) ? "mdi-check" : "mdi-close" }}
            </v-icon>
          </v-card-actions>
        </v-card>
      </v-card>

      <div v-show="showDetail" style="margin-top: 10px; height: 200px">
        <div v-if="myStep === 'vitals'">
          <Diagnostic :id="id" :name="operationName" :patid="patid" />
        </div>
        <div v-else-if="myStep === 'history'">
          <History :id="id" :name="operationName" :patid="patid" />
        </div>
        <div v-else-if="myStep === 'exam'">
          <ExamFindings :id="id" :name="operationName" :patid="patid" />
        </div>
        <div v-else-if="myStep === 'prescription'">
          <Prescription :id="id" :name="operationName" :patid="patid" />
        </div>
        <div v-else-if="myStep === 'lab'">
          <Laboratory :id="id" :name="operationName" :patid="patid" />
        </div>
        <div v-else-if="myStep === 'summary'">
          <Summary :id="id" :name="operationName" :patid="patid" />
        </div>
        <div v-else>click above to start treatment</div>
      </div>
    </div>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import WinScroll from "../../mixins/windowscroll";
import Utils from "../../mixins/utils";
// treats
import TreatFinished from "./treats/finished.vue";
import Diagnostic from "./treats/diagnostics/Diagnostics.vue";
import History from "./treats/history/MedicHistory.vue";
import ExamFindings from "./treats/exams/ExamFindings.vue";
import Laboratory from "./treats/labs/Laboratory.vue";
import Prescription from "./treats/prescription/Prescriptions.vue";
import Summary from "./treats/summary/TreatmentSummary.vue";
//
// import Extended from "./treats/extended.vue";
// import Transfers from "./treats/transfer.vue";
export default {
  props: {
    id: { type: String },
    name: { type: String },
    patid: { type: String },
  },
  components: {
    TreatFinished,
    Diagnostic,
    History,
    Laboratory,
    Prescription,
    Summary,
    ExamFindings,
  },
  mixins: [Utils, WinScroll("position")],
  data() {
    return {
      mySteppers: 1,
      finishDialog: false,
      activeColor: "rgb(4 114 193)",
      snack: {
        color: "info",
        multiLine: true,
        bar: false,
        text: "Processing ... ",
      },
      crumbs: [
        {
          exact: true,
          link: true,
          text: "Dashboard",
          to: { name: "medics.dash" },
        },
        {
          exact: true,
          link: true,
          text: "Appointment",
          to: { name: "medics.appointment" },
        },
        {
          exact: true,
          link: true,
          text: "Patient's Treatment",
          to: { name: "medics.treatment" },
        },
      ],
      treatmentList: [
        {
          step: "vitals",
          name: "vitals",
          description: "Initial patient's vitals",
          image: require("../../assets/images/treatment/stethoscope.png"),
        },
        {
          step: "history",
          name: "history",
          description: "History",
          image: require("../../assets/images/treatment/history.png"),
        },
        {
          step: "exam",
          name: "examination findings",
          description: "Examination findings",
          image: require("../../assets/images/treatment/aware.png"),
        },
        {
          step: "prescription",
          name: "Diagnosis and prescription",
          description: "Diagnosis and prescription",
          image: require("../../assets/images/treatment/prescription.png"),
        },
        {
          step: "lab",
          name: "investigations",
          description: "Investigations",
          image: require("../../assets/images/treatment/diagnostic.png"),
        },
        {
          step: "summary",
          name: "patient's records",
          description:
            "Past Record Folders of patient's from various Umohe partner practitioners",
          image: require("../../assets/images/treatment/diagnostic.png"),
        },
      ],
      //
      medTimeisDone: null,
      selectPat: 12,
      marginTop: 5 + "px",
      showDetail: false,
      operationName: null,
      myStep: null,
      selectedPatient: null,
      tabs: null,
      //
      myDiagnotics: false,
      myHistory: false,
      myEnquiry: false,
      myExams: false,
      myPrescribe: false,
      myLabs: false,
      mySummary: false,
      myExtend: false,
      myTransfer: false,
    };
  },
  watch: {
    activeColor: function (a) {
      console.log(a);
    },
  },
  computed: {
    flatapp: function () {
      if (this.position[1] > 30) {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    this.checkiSDone();
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    startPatient(step, name) {
      console.log("step", step);
      console.log("id", this._decode(this.id));
      this.operationName = name;
      this.myStep = step;
      this.selectPat = 4;
      this.showDetail = true;
    },
    checkiSDone() {
      let patreq = this._decode(this.patid);
      console.log("patreq:", patreq);
      Restful.medics.patient.request
        .find(patreq)
        .then((response) => {
          console.log(response.data);
          this.medTimeisDone = response.data.done;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    closeSession(p) {
      this.finishDialog = p.state;
    },
    submitSession(p) {
      console.log("p:", p);
      this.snack.bar = true;
      this.snack.text = "Processing ...";
      this.snack.color = "primary";

      let id = p.id;
      let done = true;
      let data = { id, done };

      console.log("data:", data);

      let self = this;
      Restful.medics.patient.request
        .finished(data)
        .then((response) => {
          // console.log(response.data);
          this.snack.text = response.data;
          this.snack.color = "success";
          this.refresh();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.text = error.response.data;
          self.snack.color = "success";
        });

      this.finishDialog = p.state;
    },
    checking(text) {
      // console.log(text);
      switch (text) {
        case "vitals":
          this.diagnostics();
          return this.myDiagnotics;
        case "history":
          this.historics();
          return this.myHistory;
        case "exam":
          this.examins();
          return this.myExams;
        case "systemic":
          this.systemic();
          return this.myEnquiry;
        case "prescription":
          this.prescribe();
          return this.myPrescribe;
        case "lab":
          this.labs();
          return this.myLabs;
        case "summary":
          this.summary();
          return this.mySummary;
        default:
          break;
      }
    },
    refresh() {
      this.checkiSDone();
      this.diagnostics();
      this.historics();
      this.examins();
      this.systemic();
      this.prescribe();
      this.labs();
      this.summary();
    },
    diagnostics() {
      let id = this._decode(this.id);
      console.log("id", id);
      Restful.medics.treatment.diagnostics
        .list(id)
        .then((response) => {
          console.log(response.data);
          if (response.data.id > 0) {
            this.myDiagnotics = true;
          } else {
            this.myDiagnotics = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    historics() {
      Restful.medics.treatment.history
        .list(this._decode(this.id))
        .then((response) => {
          // console.log(response.data);
          if (response.data.id > 0) {
            this.myHistory = true;
          } else {
            this.myHistory = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    systemic() {
      Restful.medics.treatment.enquiry
        .list(this._decode(this.id))
        .then((response) => {
          // console.log(response.data.length);
          if (response.data.length > 0) {
            this.myEnquiry = true;
          } else {
            this.myEnquiry = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    examins() {
      Restful.medics.treatment.examfind
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data.length);
          if (response.data.id > 0) {
            this.myExams = true;
          } else {
            this.myExams = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    prescribe() {
      Restful.medics.treatment.prescription
        .finished(this._decode(this.id))
        .then((response) => {
          // console.log(response.data);
          this.myPrescribe = response.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    labs() {
      console.log("id: ", this._decode(this.id));
      Restful.medics.treatment.labs
        .list(this._decode(this.id))
        .then((response) => {
          // console.log(response.data);
          if (response.data.id > 0) {
            this.myLabs = true;
          } else {
            this.myLabs = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    summary() {
      console.log("id: ", this._decode(this.id));
      Restful.medics.treatment.summary
        .list(this._decode(this.id))
        .then((response) => {
          this.isDone = response.data.is_done;
          if (response.data.id > 0) {
            this.mySummary = true;
          } else {
            this.mySummary = false;
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    menulink() {
      this.$emit("sidebar");
    },
  },
};
</script>
