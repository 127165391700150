<template>
  <v-dialog v-model="finishDialog" persistent max-width="400">
    <v-card>
      <v-card-title>finish session</v-card-title>
      <v-card-text>
        This will end the session for
        <b>{{ _decode(name) | capitalize }}</b
        >. This is ireversible, so be sure you are done with this patient.
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="red" v-on="on" @click="closeDialog">
              NO
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small text color="blue" v-on="on" @click="submit">
              yes
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
//   import Restful from "@/services/RestFul";
//   import moment from "moment";
import Utils from "../../../mixins/utils";
export default {
  props: {
    finishDialog: Boolean,
    myId: { type: String, default: null },
    name: { type: String, default: null },
  },
  mixins: [Utils],
  data() {
    return {
      responseMessage: null,
    };
  },
  created() {},
  methods: {
    closeDialog() {
      let payload = { id: parseInt(this._decode(this.myId)), state: false };
      this.$emit("closeSession", payload);
    },
    submit() {
      let payload = { id: parseInt(this._decode(this.myId)), state: false };
      this.$emit("submitSession", payload);
    },
  },
};
</script>
