<template>
  <div>
    <v-toolbar flat dense style="margin-bottom: 4px">
      <v-toolbar-title>
        {{ name | capitalize }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text x-small color="primary" v-show="isNew">new</v-btn>
      <v-btn text x-small color="primary" @click="view" v-show="!isNew">
        view
      </v-btn>
      <v-btn text x-small color="success" @click="edit" :disabled="isDisabled">
        edit
      </v-btn>
      <!-- <v-btn
        text
        x-small
        color="red"
        :disabled="isDisabled"
        @click.stop="removeDialog = true"
      >
        delete
      </v-btn>
      <DeleteNow
        :removeDialog="removeDialog"
        :myId="myId"
        @closeRemove="closeRemove"
        @submitRemove="submitRemove"
      /> -->
    </v-toolbar>
    <v-card color="transparent" :loading="loading" loader-height="1" flat>
      <div v-if="isNew === true">
        <v-overlay absolute dark :value="overlay"> </v-overlay>
        <NewOne :id="id" @submitNew="submitNew" />
      </div>
      <div v-else>
        <div v-if="editNow === true">
          <EditData :id="id" @submitEdit="submitEdit" />
        </div>
        <div v-else-if="editNow === false">
          <ViewData :id="id" />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Util from "../../../../mixins/utils";
import NewOne from "./newone.vue";
import ViewData from "./view.vue";
import EditData from "./edit.vue";
// import DeleteNow from "./delete.vue";
export default {
  props: {
    panh: { type: Number },
    id: { type: String },
    name: { type: String },
    patid: { type: String },
  },
  components: {
    NewOne,
    ViewData,
    EditData,
    // DeleteNow
  },
  mixins: [Util],
  data: () => ({
    removeDialog: false,
    myId: 0,
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    windowSize: {},
    overlay: true,
    loading: false,
    isPresent: false,
    isNew: true,
    isDisabled: true,
    editNow: false,
  }),
  created() {
    this.list();
  },
  methods: {
    list() {
      this.overlay = true;
      this.loading = true;
      let self = this;
      console.log(this._decode(this.id));
      Restful.medics.treatment.history
        .list(this._decode(this.id))
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.appointmentlist = response.data;
            this.myId = response.data.id;
            if (response.data.id > 0) {
              this.isNew = false;
              this.isPresent = true;
              this.isDisabled = false;
            } else {
              this.isNew = true;
              this.isPresent = false;
              this.isDisabled = true;
            }
          }
          this.loading = false;
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
          self.overlay = false;
          self.isPresent = false;
          self.isDisabled = true;
        });
    },
    refresh() {
      this.list();
    },
    view() {
      this.editNow = false;
    },
    edit() {
      this.editNow = true;
    },
    onResize() {
      // console.log(a);
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    submitNew(p) {
      this.snack.bar = true;
      console.log("in", JSON.stringify(p));
      let self = this;
      Restful.medics.treatment.history
        .create(p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.list();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
    },
    submitEdit(p) {
      this.snack.bar = true;
      console.log("in", p);
      let self = this;
      Restful.medics.treatment.history
        .update(p.id, p.data)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.list();
          this.editNow = false;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      console.log("p", p);
    },
    closeRemove(p) {
      this.removeDialog = p.state;
    },
    submitRemove(p) {
      this.snack.bar = true;
      console.log("in", p);
      let self = this;
      Restful.medics.treatment.history
        .remove(p.id)
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            this.snack.color = "success";
            this.snack.text = response.data;
          }
          this.list();
          this.isNew = true;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.removeDialog = p.state;
    },
  },
};
</script>
